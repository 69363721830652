import styled from 'styled-components';

import { TypographyProps } from '../../types/props';

export const H1 = styled.h1<TypographyProps>`
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ theme, color = 'mainBlack' }) => theme.colors[color]};
`;

export const H2 = styled.h2<TypographyProps>`
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ theme, color = 'mainBlack' }) => theme.colors[color]};
`;

export const H3 = styled.h3<TypographyProps>`
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ theme, color = 'mainBlack' }) => theme.colors[color]};
`;

export const H4 = styled.h4<TypographyProps>`
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ theme, color = 'mainBlack' }) => theme.colors[color]};
`;

export const H5 = styled.h5<TypographyProps>`
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ theme, color = 'mainBlack' }) => theme.colors[color]};
`;

export const H6 = styled.h6<TypographyProps>`
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ theme, color = 'mainBlack' }) => theme.colors[color]};
`;
