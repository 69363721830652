import styled from 'styled-components';

import { TypographyProps } from '../../types/props';

export const Body1 = styled.p<TypographyProps>`
  font-size: 16px;
  line-height: 22px;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ theme, color = 'mainBlack' }) => theme.colors[color]};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const Body2 = styled.p<TypographyProps>`
  font-size: 14px;
  line-height: 20px;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ theme, color = 'mainBlack' }) => theme.colors[color]};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 16px;
    line-height: 22px;
  }
`;
