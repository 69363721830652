import * as React from "react"
import type { HeadFC } from "gatsby"
import styled from 'styled-components';

import Base from "../../../layout/Base"
import { H1, H2, H3, H4, H5 } from "../../../components/Fonts/HTags";
import { fontWeightType } from "../../../constants/enums";
import { Body1, Body2 } from "../../../components/Fonts/Body";
import { Footnote } from "../../../components/Fonts/Footnote";
import { Caption1, Caption2 } from "../../../components/Fonts/Caption";
import { Subtitle } from "../../../components/Fonts/Subtitle";

const Container = styled.div`
  display: flex;
  margin: 20px 0 20px 20px;
  * {
    margin-right: 20px;
  }
`;

const Fonts = () => {
  const array1 = [
    fontWeightType.ExtraBold,
    fontWeightType.Bold,
    fontWeightType.SemiBold,
    fontWeightType.Medium,
    fontWeightType.Regular,
    fontWeightType.Light,
  ];

  const array2 = [
    fontWeightType.ExtraBold,
    fontWeightType.Bold,
    fontWeightType.SemiBold,
    fontWeightType.Medium,
    fontWeightType.Regular,
  ];

  const array3 = [
    fontWeightType.ExtraBold,
    fontWeightType.Bold,
    fontWeightType.SemiBold,
    fontWeightType.Medium,
  ];

  return (
    <Base>
      <Container>
        <h1>Typography</h1>
      </Container>
      <Container>
        {array1.map(a => {
          return <H1 fontWeight={a}>H1</H1>
        })}
      </Container>
      <Container>
        {array1.map(a => {
          return <H2 fontWeight={a}>H2</H2>
        })}
      </Container>
      <Container>
        {array1.map(a => {
          return <H3 fontWeight={a}>H3</H3>
        })}
      </Container>
      <Container>
        {array1.map(a => {
          return <H4 fontWeight={a}>H4</H4>
        })}
      </Container>
      <Container>
        {array1.map(a => {
          return <H5 fontWeight={a}>H5</H5>
        })}
      </Container>
      <Container>
        {array2.map(a => {
          return <Subtitle fontWeight={a}>Subtitle</Subtitle>
        })}
      </Container>
      <Container>
        {array2.map(a => {
          return <Body1 fontWeight={a}>Body1</Body1>
        })}
      </Container>
      <Container>
        {array3.map(a => {
          return <Body2 fontWeight={a}>Body2</Body2>
        })}
      </Container>
      <Container>
        {array3.map(a => {
          return <Footnote fontWeight={a}>Footnote</Footnote>
        })}
      </Container>
      <Container>
        {array3.map(a => {
          return <Caption1 fontWeight={a}>Caption1</Caption1>
        })}
      </Container>
      <Container>
        {array3.map(a => {
          return <Caption2 fontWeight={a}>Caption2</Caption2>
        })}
      </Container>
    </Base>
  )
}

export default Fonts

export const Head: HeadFC = () => <title>Fonts</title>
