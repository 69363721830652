import styled from 'styled-components';
import { TypographyProps } from '../../types/props';

export const Subtitle = styled.p<TypographyProps>`
  font-size: 18px;
  line-height: 24px;
  font-weight: ${({fontWeight}) => fontWeight};
  color: ${({theme, color = 'mainBlack'}) => theme.colors[color]};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 20px;
    line-height: 26px;
  }
`;
